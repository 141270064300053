<template>
<div>
	<v-data-table :items="map" :headers="headers"></v-data-table>
	<Export :rows="map" :columns="columns" :name="`${name}`"></Export>	
</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Export from "@/components/admin/panels/Export"

	export default {
		name: "Results", 
		props: {
			
		},
		data: () => {
			return {
				name: "data"
			}
		},
		components: {
			Export
		},
		computed: {
			...Vuex.mapState({
				goals: state => state.goals,
				goalGroups: state => state.goalGroups
			}),
			questions(){
				return this.$store.getters.questionArray;
			},
			assessmentMap(){
				return this.$store.getters.igMapped;
			},
			goalsMap(){
				return this.$store.getters.completionRatesFlat;
			},
			map(){
				var self = this;
				if( !self.goalsMap || !self.assessmentMap ){
					return;
				}
				var data = [];

				self.goalsMap.forEach( goal => {
					var obj = {
						section: self.assessmentMap[goal.department] ? self.assessmentMap[goal.department].name : '',
						goalgroup: self.assessmentMap[goal.department] ? self.assessmentMap[goal.department].parts[goal.part].name : '',
						goal: self.goals[goal.goal] ? self.goals[goal.goal].name : ''
					};
					if( goal.data ){
						Object.keys(goal.data).forEach( q => {
							obj[q] = goal.data[q];
						} )
					}
					data.push(obj)
				})

				return data;
			},
			headers(){
				var cols = [
					{text: this.mwtr.Section, value:"section" },
					{text: this.mwtr.GoalGroupsNavItem, value:"goalgroup" },
					{text: this.mwtr.GoalNavItem, value:"goal" },
				]

				var questions = this.questions;
				if( questions ){
					questions.forEach( q => {
						cols.push({ value: q.id, text: q.title })
					})
				}
				return cols
			},
			columns(){
				var obj = {}
				this.headers.forEach( (c) => {
					obj[c.text] = c.value;
				})
				return obj;
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"